.approach-steps {
  background-color: $color-accent;
  padding: ($spacing-y * 3) 0;

  ._separator {
    display: none;

    @include mobile() {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  & > .container {
    position: relative;
    max-width: 1200px;
    padding: 0 3rem;

    @include tablet() {
      padding: 0 2rem;
    }


    @include mobile(){
      padding: 0 1rem;
    }
  }

  ._arrow {
    right: $spacing-x * 4;
    top: 50%;
    position: absolute;
    transform: translateY(-50%);

    @include mobile() {
      display: none;
    }
  }

  ._title {
    h2, img {
      display: inline-block;
    }

    h2 {
      font-size: 2rem;
      margin-bottom: $spacing-y / 2;

      @include tablet() {
        font-size: 1.75rem;
      }
    }

    img {
     width: 38px;
     margin-left: $spacing-x;

     @include tablet() {
       width: 30px;
       margin-left: $spacing-x / 2;
     }
    }
  }

  p:last-of-type {
    margin-bottom: 0;
  }

  ._step-label {
    font-size: 1.5rem;
    text-transform: uppercase;
    line-height: 1;
    text-align: center;
    position: relative;
    z-index: 2;
    margin: 1rem 0;

    & > div {
      display: inline-block;
      background-color: $color-accent;
    }

    @include tablet() {
      margin: 0.5rem 0;
    }

    span {
      font-size: 2.75rem;
    }
  }

  ._step-content {
    width: 100%;
    position: relative;

    & > img {
      position: absolute;

      &._x {
        top: 50%;
        width: 150px;

        @include mobile() {
          display: none;
        }
      }

      &._y {
        left: 50%;
        top: calc(50% - 4px);
        transform: translate(-50%, -50%);

        @include mobile() {
          display: none;
        }
      }
    }

    ._content {
      width: 375px;
      padding: 0.5rem 0;
      background-color: $color-accent;
      position: relative;
      z-index: 2;

      @include desktop() {
        width: 275px;
      }

      @include tablet() {
        width: 225px;
      }

      @media screen and (max-width: 875px) {
        width: 180px;
      }

      @include mobile() {
        width: 250px;
        padding: ($spacing-y) 0;
      }
    }

    &.-center {
      margin-top: $spacing-y * 2;

      @include mobile() {
        margin-top: 0;
      }

      ._content {
        text-align: center;
        margin: 0 auto;
        width: 450px;

        @include desktop() {
          width: 450px;
        }

        @include tablet() {
          width: 450px;
        }

        @include mobile() {
          width: auto;
        }

        h2 {
          text-transform: uppercase;
          font-size: 1.75rem;
        }
      }
    }

    &.-left {
      ._content {
        text-align: left;
      }

      ._x {
        left: calc(50% - 160px);
      }
    }

    &.-right {
      ._content {
        text-align: right;
        margin-left: auto;
      }

      ._x {
        left: calc(50% + 15px);
      }
    }
  }
}
