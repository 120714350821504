.triangle-left {
  position: relative;

  &::before {
    position: absolute;
    content: "";
    width: 0;
    height: 0;
    bottom: 0;
    left: 0;
    z-index: 3;

    border-left: 37.5px solid $white;
    border-top: 37.5px solid transparent;
    border-right: 37.5px solid transparent;
    border-bottom: 37.5px solid $white;

    @include mobile() {
      border-width: 15px;
    }
  }

  &.-accent::before {
    border-left: 37.5px solid $color-accent;
    border-bottom: 37.5px solid $color-accent;

    @include mobile() {
      border-width: 15px;
    }
  }
}

.triangle-right {
  position: relative;

  &::before {
    position: absolute;
    content: "";
    width: 0;
    height: 0;
    bottom: 0;
    right: 0;
    z-index: 3;

    border-left: 37.5px solid transparent;
    border-top: 37.5px solid transparent;
    border-right: 37.5px solid $color-accent;
    border-bottom: 37.5px solid $color-accent;

    @include mobile() {
      border-width: 15px;
    }
  }

  &.-black::before {
    border-right: 37.5px solid $color-primary;
    border-bottom: 37.5px solid $color-primary;

    @include mobile() {
      border-width: 15px;
    }
  }

  &.-white::before {
    border-right: 37.5px solid $white;
    border-bottom: 37.5px solid $white;

    @include mobile() {
      border-width: 15px;
    }
  }
}
