.articles {
  background-color: $color-accent;
  padding: ($spacing-y * 4) 0 $spacing-y;
}

.article-entry {

  h3 {
    font-size: 2rem;
    margin-bottom: $spacing-y / 4;

    a::after {
      display: none;
    }
  }

  a {
    color: $black;
    font-weight: bold;
    font-family: $font-heading;

    &:after {
      content: '';
      display: inline-block;
      width: 18px;
      height: 18px;
      background-image: url('/images/arrow-right.svg');
      background-repeat: no-repeat;
      position: relative;
      top: 2px;
      right: -5px;
    }
  }

  li {
    border-bottom: 3px solid $black;
    padding-bottom: $spacing-y;
    margin-bottom: $spacing-y * 3;
  }
}

.breadcrumbs {
  padding-top: $spacing-y;
  padding-bottom: $spacing-y;

  a {
    color: $color-primary;
    font-size: 1.2rem;
    font-family: $font-heading;
  }
}


.article-main {

  margin-bottom: $spacing-y * 4;

  time {
    display: block;
    margin-bottom: $spacing-y;
  }

  p {
    line-height: 1.8;
  }

  em {
    font-weight: bold;
  }

  video {
    width: 100%;
  }

  img {
    width: 100%;
  }

  ol {
    list-style: numbered;
    margin-left: $spacing-x;

    li {
      font-weight: bold;
      margin-bottom: $spacing-y / 2;
    }
  }

  ul {
    padding-left: $spacing-x;

    li {
      position: relative;
      margin-bottom: $spacing-y / 2;
      margin-left: $spacing-x;
      padding-left: $spacing-x;

      &::before {
        content: '';
        width:4px;
        height:4px;
        border-radius: 50%;
        display: block;
        background: #999;
        position: absolute;
        left: -10px;
        top: 10px;
      }
    }
  }

  table {
    margin-bottom: $spacing-y;
    width: 100%;

    td {
      border: 1px solid #fff;
      padding: 1rem;
      text-align: right;

    }

    tbody tr:nth-child(odd) {
      background: #e4e4e4;
    }

    tbody tr:nth-child(even) {
      background: #f4f4f4;
    }

    thead tr {
      background: $color-accent;
      font-weight: bold;
    }

    tr > td:first-child {
      width: 60%;
    }

    tbody tr:last-child {
      font-weight: bold;
    }

    tr:empty,
    td:empty {
      display: none;
    }
  }


}

.article-related {
  background-color: $color-accent;
  padding: ($spacing-y * 4) 0 $spacing-y;

  h2 {
    // font-size: 4rem;
    @extend h1;
    margin-bottom: $spacing-y * 4;
  }
}

.article-main__cta {
  h2 {
    @extend h1;
  }
}

.tldr {
  background: $color-accent;
  padding: $spacing-y;

  ul {
    padding-left: $spacing-x;
    li {
      // display: flex;
      // align-items: center;
      position: relative;
      margin-bottom: $spacing-y / 2;
      margin-left: $spacing-x;
      padding-left: $spacing-x;

      &::before {
        content: '';
        width:4px;
        height:4px;
        border-radius: 50%;
        display: block;
        background: $black;
        position: absolute;
        left: -10px;
        top: 10px;
      }
    }
  }


  em {
    font-weight: bold;
  }
}


.content-grid {
  display: grid;
  grid-gap: $spacing-x * 2;
  grid-template-columns: 1fr 400px;
  grid-template-rows: 1fr auto;
  margin-bottom: $spacing-y * 2;

  @include tablet() {
    display: block;

    & > * {
      margin-bottom: $spacing-x * 2;
    }
  }
}

.content-grid__img {
  grid-column: 1 / 2;
  grid-row: 1 / 2;

  position: relative;

  .btn {
    position: absolute;
    bottom: $spacing-y;
    right: $spacing-x;
  }
}

.content-grid--has-img .content-grid__text {
  grid-column: 1 / 2;
  grid-row: 2 / 3;
}

.content-grid__text {
  grid-column: 1 / 2;
  grid-row: 1 / 3;
}

.content-grid__tldr {
  grid-column: 2 / 3;
  grid-row: 1 / 3;
}


.aricle-main__subtitle {
  font-family: $font-heading;
  font-size: 1.2rem;
  max-width: 674px;
}
