nav ul {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

nav li {
  a, span {
    color: $white;
    font-family: $font-heading;
    font-size: 1.2rem;
  }
}
