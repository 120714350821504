.solutions-steps {
  background-color: $color-accent;
  color: $color-primary;
  padding: ($spacing-y * 3) 0;

  @include mobile() {
    padding: $spacing-y 0;

    &.triangle-left::before {
      border-width: 0;
    }
  }

  h2 {
    font-size: 1.25rem;
    font-weight: $font-weight-bold;
  }

  ul {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  li {
    position: relative;
    padding-bottom: $spacing-y * 4;
    padding-top: $spacing-y * 4;

    @include mobile() {
      padding-top: $spacing-y * 4;
      padding-bottom: $spacing-y * 4;
    }

    &:nth-of-type(1) {
      padding-right: $spacing-x * 2;
      width: calc((100% / 3) - #{$spacing-x * 4});

      @include mobile() {
        width: 100%;
        max-width: 400px;
        margin: 0 auto;
        padding-right: 0;
      }
    }

    &:not(:first-of-type) {
      width: calc(100% / 3);
      padding-left: $spacing-x * 4;

      @include mobile() {
        width: 100%;
        max-width: 400px;
        margin: 0 auto;
        padding-left: 0;
        padding-top: $spacing-y * 6;

        span {
          top: $spacing-y * 1.5;
        }
      }

      &::before {
        content: "";
        background-image: url('/images/step-separator-vertical.svg');
        background-repeat: no-repeat;
        height: 100%;
        width: 2rem;
        background-size: contain;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 2;

        @include mobile() {
          background-image: url('/images/step-separator-horizontal.svg');
          height: 2rem;
          width: 100%;
          left: auto;
        }
      }
    }

    img {
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);

      @include mobile() {
        bottom: $spacing-y * 1.5;
      }
    }
  }

  span {
    color: $white;
    font-size: 8rem;
    position: absolute;
    top: 0;
    right: $spacing-x / 2;
    font-family: $font-heading;
    line-height: 1.1;

    @include tablet() {
      font-size: 6.5rem;
      line-height: 1.5;
    }
  }
}

.solutions-services {
  background-color: $color-primary;
  color: $white;
  padding: ($spacing-y * 3) 0;

  h2 {
    margin-bottom: $spacing-y * 1.5;
  }

  @include mobile() {
    ul {
      display: none;

      &.active {
        display: block;
      }
    }
  }

  ._sections {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    h3 {
      font-size: 1.25rem;
      margin-bottom: $spacing-y * 0.5;

      span {
        display: none;
      }

      @include mobile() {
        cursor: pointer;
        margin-bottom: $spacing-y;

        span { display: inline; }
      }
    }

    p {
      font-size: 0.8rem;
      color: #6b6b6b;

      &:first-of-type {
        margin-top: $spacing-y;
      }
    }

    & > * {
      width: 30%;

      @include mobile() {
        width: 100%;
        margin-bottom: $spacing-y;

        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }

    li {
      margin-bottom: 0.5rem;
      margin-left: 10px;
      position: relative;

      &::before {
        content: "\f105";
        font-family: 'Font Awesome\ 5 Free';
        display: inline-block;
        font-size: 1rem;
        color: $color-accent;
        margin-left: -12px;
        font-weight: 700;
        position: absolute;
      }

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
}

.solutions-proofs {
  background-color: $color-accent;
  padding: ($spacing-y * 2) 0;

  h2 {
    margin-bottom: $spacing-y * 1.5;

    @include mobile() {
      text-align: center;
    }
  }

  ul {
    display: flex;
    justify-content: space-between;
    align-items: stretch;

    @include mobile() {
      flex-wrap: wrap;
      justify-content: center;
    }
  }

  li {
    background-color: $white;
    width: 225px;
    text-align: center;
    padding: 1.25rem 0.5rem;

    @include desktop() {
      width: 175px;
    }

    @include tablet() {
      width: 165px;
    }

    @include mobile() {
      width: 215px;
      height: 225px;
      margin-left: $spacing-x;
      margin-right: $spacing-x;
      margin-bottom: $spacing-y * 2;

      &:last-of-type {
        margin-bottom: 0;
      }
    }

    @include mobile-sm() {
      width: 180px;
    }

    img {
      height: 38px;
      width: auto;
      margin-bottom: $spacing-y;
    }

    h3 {
      font-size: 2.5rem;
      color: $color-primary;
      margin-bottom: 0;
      line-height: 1;
    }

    p {
      font-size: 1.1rem;
      margin-bottom: 0;
    }
  }
}
