#difference {
  background-color: $color-accent;
  padding: ($spacing-y * 3) 0;

  .container > p {
    padding-right: 20%;

    @include mobile() {
      padding-right: 0;
    }
  }

  .btn {
    margin-top: $spacing-y * 1;
  }
}

#solutions-home {
  padding: ($spacing-y * 3) 0;

  & > * {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    & > * {
      flex-basis: 50%;
    }

    ul {
      flex-wrap: wrap;
    }

    ._content {
      padding-right: $spacing-x * 2;
    }

    nav {
      padding-left: $spacing-x * 2;

      a {
        font-weight: $font-weight-bold;
      }

      ul {
        display: block;
      }
    }

    @include mobile() {
      & > * {
        flex-basis: 100%;
      }

      ._content {
        padding-right: 0;
      }

      nav {
        padding-left: 0;
      }
    }

    li {
      margin-bottom: $spacing-y;
      display: block;

      @include mobile() {
        flex-basis: 100%;
      }

      a {
        color: $color-primary;
        font-family: $font-heading;
        font-size: 1.5rem;
        @include underline($black);

        &:hover {
          border-color: $color-accent;
        }
      }

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
}


#engagement {
  background-color: $color-primary;

  .container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    gap: 2rem;

    @include tablet() {
      display: block;
      gap: 0;
      padding-block: 3rem 2rem;
    }

    ._content {
      color: $white;
      margin: 0 auto;
      max-width: 600px;
      padding-block: 2rem;

      @include desktop-large() {
        max-width: 900px;
      }

      @include tablet() {
        max-width: unset;
        padding-bottom: 0;
      }
    }

    figure {
      overflow: hidden;
      height: 100%;

      img {
        height: 100%;
        object-fit: cover;
      }
    }

    .btn.-light {
      background-color: $white;
      color: $color-primary;

      &:hover {
        background-color: $color-accent;
      }
    }
  }
}