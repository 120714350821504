*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-size: $font-size-base;
  @include tablet() {
    font-size: $font-size-base-tablet;
  }

  @include mobile() {
    font-size: $font-size-base-mobile;
  }
}

body {
  font-family: $font-primary;
  font-size: $font-size-base;
  color: $color-primary;
  line-height: 1.5;
  background: $color-bg-default;

  @include tablet() {
    font-size: $font-size-base-tablet;
  }

  @include mobile() {
    font-size: $font-size-base-mobile;
  }

  // for when mobile menu is open
  &.lock {
    overflow: hidden;
  }
}

img {
  max-width: 100%;
}

a {
  text-decoration: none;
}

p {
  margin-bottom: $spacing-y;
}


// layout
//-----------------------

.container {
  @include container();
}

// components
//-----------------------

.logo {
  width: $width-logo;
}


