@charset "UTF-8";

// Configuration and helpers
@import 'abstracts/variables';
@import 'abstracts/mixins';

// Vendors
@import 'vendors/reset';
@import 'vendors/normalize';

// Base stuff
@import 'base/base';
@import 'base/typography';

// Layout stuff
@import 'layout/nav';
@import 'layout/header';
@import 'layout/footer';

// Components
@import 'components/sections';
@import 'components/buttons';
@import 'components/triangles';
@import 'components/testimonials';
@import 'components/forms';

// Page-specific styles
@import 'pages/home';
@import 'pages/contact';
@import 'pages/approche';
@import 'pages/solutions';
@import 'pages/equipe';
@import 'pages/articles';
@import 'pages/engagement';

.legal-block {

    &.hidden { display: none; }

    ul {
        list-style-type: disc;
        list-style-position: inside;
        margin-bottom: 2rem;
    }

    li {
        margin-bottom: 0.5rem;
    }

    a {
        color: $color-primary;
        font-family: futura-pt, sans-serif;
        font-size: 1.5rem;
        border-bottom: 2px solid $color-primary;
        padding-bottom: 1px;
    }
}

nav.-legal {
    text-align: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 2rem;
    margin-top: 2rem;
}

nav.-legal a {
    margin-inline: 1.5rem;
    margin-bottom: 0.5rem;
    font-size: 1.25rem;
    cursor: pointer;
    color: $color-primary;
    font-family: futura-pt, sans-serif;
    font-size: 1.5rem;
    border-bottom: 2px solid $color-primary;
    padding-bottom: 1px;
}

.document {
  padding-bottom: 4rem;

  & > * {
    margin-bottom: 1rem;
  }

  h1,
  h2 {
    &::after {
      display: none;
    }
  }

  h2 {
    margin-top: 2rem;
  }

  ul {
    line-height: 1.5;
    font-weight: 100;
    list-style: disc;
    padding-left: 2rem;
  }

  nav {
    display: flex;
    gap: 1rem;
    justify-content: center;
    margin-bottom: 4rem;
  }
}
