.engagement {
    ._content {
        max-width: 600px;
    }
}

.mot-patrice {
    background-color: $color-accent;
    position: relative;

    .container {
        max-width: 750px;
        padding-block: 3rem;
        margin: 0 auto;

        @include desktop-large(){
            max-width: 1200px;
        }
    }

    em {
        font-style: italic;
    }

    p:last-of-type {
        text-align: right;
    }

    img {
        margin-left: auto;
        display: block;
    }

    &::before {
        border-left-color: $color-primary;
        border-bottom-color: $color-primary;
    }
}

.pourquoi {
    @include mobile(){
        padding-block: 4rem 3rem;
    }

    ul {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-auto-rows: 1fr;
        justify-content: center;

        @include mobile() {
            display: block;
        }

        h3 {
            font-size: 1.5rem;
        }

        li {
            padding: 1rem 2rem;

            &:nth-of-type(2) {
                border-left: 2px solid $white;
                border-right: 2px solid $white;

                @include mobile() {
                    border: none;
                    border-top: 2px solid $white;
                    border-bottom: 2px solid $white;
                }
            }

            &:first-of-type {
                padding-left: 0;
            }

            &:last-of-type {
                padding-right: 0;
            }

            @include mobile() {
                padding: 2rem 0;
            }
        }
    }
}

.bourse {
    padding: ($spacing-y * 4) 0;

    @include mobile() {
        padding-block: 3rem 2rem;
    }

    .container > p {
        max-width: 500px;
    }

    ul {
        max-width: 900px;
        margin: 3rem auto;

        @include desktop-large(){
            max-width: 1200px;
        }
    }

    h3 {
        max-width: 900px;
        margin: 3rem auto 0;
        border-bottom: 2px solid $color-primary;
        padding-bottom: 1rem;
        font-size: 1.7rem;

        @include desktop-large(){
            max-width: 1200px;
        }
    }

    li {
        display: grid;
        gap: 3rem;
        grid-template-columns: .5fr 1fr;
        align-items: center;
        margin-bottom: 3rem;

        @include tablet() {
            align-items: start;
            gap: 2rem;
        }

        @include mobile() {
            display: block;


            h5 {
                font-size: 1.1rem;
            }
        }

        h4 {
            margin-bottom: 0;
            font-size: 1.3rem;
            font-weight: $font-weight-bold;

            @include mobile(){
                font-size: 1.5rem;
                margin-top: .5rem;
            }
        }
    }
}

.causes {
    padding-block: 4rem;

    ul {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 2rem;

        @include mobile(){
            display: block;

            li:first-of-type{
                margin-bottom: 2rem;
            }
        }
    }

    h3 {
        font-size: 1.5rem;
        margin-block: .5rem;
    }

    li:last-of-type > p {
        margin-bottom: 0;
    }
    .qgda {
        color: white;
    } 
}

.signataire {
    padding: 3rem 0;

    h2 {
        font-size: 1.5rem;

        @include tablet(){
            font-size: 1.5rem;
            margin-top: 1rem;

            br {
                display: none;
            }
        }
    }

    .img-content {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 2rem;
        align-items: center;
        margin-bottom: 3rem;

        > div {
            max-width: 500px;

            @include mobile(){
                max-width: unset;
            }
        }


        @include tablet(){
            display: block;

            &:last-of-type {
                margin-bottom: 0;
            }
        }
    }

    em {
        font-style: italic;
    }
    
    .fondation {
        p:last-of-type {
            text-align: right;
        }

        img {
            order: 1;
        }

        div {
            margin: 0 auto;

            @include tablet(){
                margin: 0;
            }
        }
    }
}

.autres {
    position: relative;
    background-color: $color-accent;
    text-align: center;
    padding-block: 3rem;

    p {
        font-weight: $font-weight-bold;
        font-family: $font-heading;
        font-size: 1.2rem;
        margin-bottom: .5rem;
    }
}


.inspire {
    .container {
        margin-right: 0;
        padding-right: 0;
        display: grid;
        grid-template-columns: 25% 75%;
        align-items: center;
        overflow: hidden;

        @include desktop() {
            grid-template-columns: 40% 60%;
        }

        @include tablet() {
            display: block;
            padding: 0;
            text-align: center;
        }
    }

    .cta {
        padding-right: 2rem;

        @include tablet() {
            padding: 2rem;
        }

        .btn:hover {
            background-color: $color-accent;
            color: $color-primary;
        }
    }

    li,
    img {
        aspect-ratio: 1/1;
    }

    ul {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
    }
}
