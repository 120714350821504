%heading {
  font-family: $font-heading;
  margin-bottom: $spacing-y;
}

h1,
h2,
h3,
h4,
h5 {
  @extend %heading;
}

h1 {
  font-size: 3rem;
  line-height: 1;
  font-weight: $font-weight-bold;

  @include tablet() {
    font-size: 2.75rem;
  }

  @include mobile() {
    font-size: 2rem;
  }
}

h2 {
  font-size: 2.2rem;
  line-height: 1.25;
  font-weight: $font-weight-bold;

  @include mobile() {
    font-size: 1.9rem;
  }
}

h3 {
  line-height: 1.25;
  font-weight: $font-weight-bold;
}
