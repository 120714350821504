#testimonials {
  position: relative;
  background-color: black;

  ._quote ._content {
    padding-left: 1rem;
    position: relative;
    max-width: 700px;

    &::before {
      content: "’’";
      position: absolute;
      top: 0;
      left: 0;
      font-size: 2rem;
      line-height: 0;
      transform: rotate(180deg);
    }
  }

  ._author {
    margin-top: $spacing-y * 3;
    text-align: right;
    font-size: 1.25rem;

    @include mobile() {
      br { display: none; }
    }
  }

  ._slide {
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $white;
    position: relative;
    padding: ($spacing-y * 4) 0 ($spacing-y * 5);

    & > .container {
      position: relative;
      z-index: 2;
    }

    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      width: 100%; height: 100%;
      background-color: rgba(64,64,64,0.55);
    }
  }

  .slider-btn {
    margin-right: 1rem;
    height: .75rem;
    width: .75rem;
    background-color: $white;
    border-radius: 50%;
    border: 1px solid $white;
    display: inline-block;

    &.selected {
      border-color: $color-accent;
      background-color: $color-accent;
    }
  }
}

#js-slider-controls {
  position: absolute;
  bottom: $spacing-y;
  left: 50%;
  transform: translateX(-50%);
}
