.team-members {
  background-color: $color-accent;
  padding: ($spacing-y * 4) 0 $spacing-y;

  .container { max-width: 1600px; }

  ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    &::after {
      content: "";
      width: calc((100% / 3));
    }

    @include mobile() {
      justify-content: center;
    }
  }

  li {
    background-color: $white;
    width: calc((100% / 3) - (#{$spacing-x} * 2));
    margin-bottom: $spacing-y * 3;
    margin-left: $spacing-x;
    margin-right: $spacing-x;
    position: relative;

    @media screen and (max-width: 850px) {
      width: calc((100% / 2) - (#{$spacing-x} * 2));
    }

    @include mobile() {
      width: 100%;
      max-width: 350px;
    }

    figure {
      background-size: cover;
      background-position: center center;
      width: 100%;
      padding-top: 350px;

      @include tablet() {
        padding-top: 300px;
      }

      @media screen and (max-width: 850px) {
        padding-top: 375px;
      }

      @include mobile-sm() {
        padding-top: 350px;
      }
    }

    h2 {
      font-size: 1.25rem;
      margin-bottom: $spacing-y;
    }

    ._role,
    ._email {
      margin-bottom: 0;
    }

    p, a {
      font-size: 14px;

      @include tablet() {
        font-size: 12px;
      }

      @include mobile() {
        font-size: 1rem;
      }
    }

    a {
      color: $color-primary;

      &:hover {
        @include underline($color-primary);
      }
    }

    figcaption {
      padding: 0.75rem;
      background-color: $white;
      font-family: $font-heading;
      position: relative;
      padding-bottom: 3rem;
    }

    ._toggle {
      font-size: 14px;
      position: absolute;
      bottom: 0.75rem;
      left: 0.75rem;
      width: 100%;

      & > * {
        @include underline($color-accent);
        cursor: pointer;
      }

      @include tablet() {
        font-size: 12px;
      }

      @include mobile() {
        font-size: 1rem;
      }

      & > *:hover {
        @include underline($color-primary);

        i {
          color: $color-primary;
        }
      }

      i {
        color: $color-accent;
      }
    }

    ._full-content,
    ._phone {
      display: none;
    }

    &.active {
      ._full-content {
        display: block;
      }

      ._role {
        display: none;
      }

      ._phone {
        display: block;
      }

      figure {
        padding-top: 0;
        background-image: none;
      }
    }
  }
}

.single-team-member {
  padding: ($spacing-y * 3) 0;
  margin-bottom: $spacing-y * 3;

  .container {
    display: grid;
    grid-template-columns: auto 1fr;
  }

  @include mobile() {
    padding-top: 0;

    .container {
      padding-left: 0;
      padding-right: 0;
      grid-template-columns: 1fr;
    }

    ._content {
      @include paddingX($content-gutter * 1.5);
    }
  }

  h1 {
    font-size: 2rem;
    @include underline($color-accent);
    display: inline-block;
  }

  ul {
    margin-bottom: $spacing-y;
  }

  li {
    font-weight: 500;
  }

  figure {
    width: 400px;
    margin-right: $spacing-x * 3;

    @include tablet() {
      width: 325px;
    }

    @include mobile() {
      width: 100%;
      float: none;
      margin-bottom: $spacing-y;
    }

    img {
      width: 100%;
      vertical-align: bottom;
    }

    figcaption {
      width: 100%;
      background-color: $color-accent;
      font-family: $font-heading;
      padding: 1rem;

      a {
        display: inline-block;
        color: $color-primary;
        text-decoration: none;
        padding-bottom: 2px;

        &:hover {
          @include underline($color-primary);
          padding-bottom: 0;
        }
      }

      i {
        color: $white;
        margin-right: 0.5rem;
        display: inline-block;
      }
    }
  }
}
