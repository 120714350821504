#hero {
  background-size: cover;
  background-position: center center;
  padding: ($spacing-y * 3) 0;
  color: $white;
  position: relative;
  height: calc(100vh - 97px);
  display: flex;
  align-items: center;
  min-height: 450px;

  @include desktop() {
    height: auto;
    padding: ($spacing-y * 3) 0;
    min-height: auto;
  }

  &::after {
    background-color: rgba(64,64,64,0.55);
    top: 0; left: 0; bottom: 0; right: 0;
    width: 100%;
    height: 100%;
    content: "";
    position: absolute;
  }

  & > * {
    position: relative;
    z-index: 2;
    width: 100%;
  }

  ._content {
    p {
      max-width: 600px;
      font-size: 1.2rem;

      @include mobile() {
        max-width: 250px;

        br {
          display: none;
        }
      }
    }
  }

  .btn {
    margin-top: .5rem;
  }

  br.-mobile {
    display: none;
  }

  @include mobile() {
    br.-mobile {
      display: inline;
    }
  }

  ._scroll-down {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    width: 30px;
    height: 30px;
    color: $white;
    font-size: 2.75rem;
    cursor: pointer;
    border-style: solid;
    border-color: #fff;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
    transition: border-width 75ms ease-in-out;

    &:hover {
      border-bottom-width: 4px;
      border-right-width: 4px;
    }
  }
}

.img-with-text {
  padding: ($spacing-y * 4) 0;

  @include mobile() {
    padding-top: 0;

    br { display: none; }
  }

  & > .container {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include mobile() {
      flex-wrap: wrap;
      padding-left: 0;
      padding-right: 0;
    }
  }

  .container > img {
    max-width: 600px;
    width: 50%;
    padding-right: $spacing-x * 2;

    @include mobile() {
      max-width: none;
      width: 100%;
      padding-right: 0;
      margin-bottom: $spacing-y * 3;
    }
  }

  .video-link {
    background-size: cover;
    background-position: center center;
    position: relative;

    button {
      bottom: 1rem;
      right: 1rem;
      position: absolute;

      @include mobile() {
        bottom: 3rem;
        right: 2rem;
      }
    }

    @include mobile() {
      padding: $spacing-y $spacing-x;
    }
  }

  ._content {
    padding-left: $spacing-x * 2;
    text-align: right;

    @include mobile() {
      padding-left: 0;
      text-align: left;
      padding-left: $spacing-x;
      padding-right: $spacing-x;
    }
  }
}

.contrast {
  background-color: $color-primary;
  color: $white;
  padding: ($spacing-y * 4) 0;

  p:last-of-type {
    margin-bottom: 0;
  }
}

#patrice {
  background-color: $color-accent;
  padding: ($spacing-y * 3) 0;

  &.-contrast {
    background-color: $color-primary;
    color: $white;
  }

  .container > p {
    padding-right: 20%;

    @include mobile() {
      padding-right: 0;
    }
  }

  .btn {
    margin-top: $spacing-y * 1;
  }

  &.-contrast .btn:hover {
    background-color: $white;
    color: $color-primary;
  }
}

.video-modal {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,.8);
  z-index: 1000;

  &.active { display: block; }

  .iframe-wrapper {
    width: 80%;
    max-width: 1024px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    padding-top: 56.25%;
  }

  iframe {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1002;
  }

  ._close {
    position: fixed;
    top: 1.3125rem;
    right: 0.875rem;
    z-index: 1001;
    color: #fff;
    font-size: 30px;
    cursor: pointer;

    &:hover {
      color: $color-accent;
    }
  }
}
