.contact-hero {
  display: flex;
  align-items: stretch;

  @include mobile() {
    flex-wrap: wrap;
  }

  iframe {
    width: 55%;

    @include mobile() {
      width: 100%;
      height: 350px;
    }
  }

  ._content {
    padding: ($spacing-y * 4) ($spacing-x * 4);
    width: 45%;
    background-color: $color-accent;

    @include mobile() {
      width: 100%;
    }

    h1 {
      font-size: 4rem;
    }

    a {
      color: $color-primary;
      display: inline-block;
      margin-bottom: $spacing-y;
      padding-bottom: 2px;

      i {
        color: $white;
        margin-right: 0.5rem;
      }

      &:hover {
        @include underline($color-primary);
        padding-bottom: 0;
      }
    }
  }
}

.contact-page {
  padding: ($spacing-y * 3) 0 0;
  h2 {
    font-size: 3rem;
    max-width: 550px;
    line-height: 1.25;
  }

  p {
    max-width: 450px;
  }
}
