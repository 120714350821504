section.contact {
  padding: ($spacing-y * 3) 0;

  h2 {
    margin-bottom: $spacing-y * 1.5;
  }
}

form {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;

  input,
  textarea,
  select {
    border: none;
    border-bottom: 2px solid $color-accent;
    padding-bottom: 8px;
    margin-bottom: ($spacing-y * 2);
    width: 100%;
  }

  input[type="checkbox"] {
    width: auto;
    margin-right: 0.5rem;
    margin-bottom: 0;
    margin-top: 5px;
  }

  label {
    display: inline-block;
    margin-bottom: 0.25rem;
  }

  ul {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: $spacing-x;
    margin-bottom: $spacing-y * 2;

    @include mobile() {
      grid-template-columns: 1fr;
    }

    li {
      display: flex;
      align-items: flex-start;
    }
  }

  fieldset {
    padding: 0;
    width: 47.5%;

    &.full-width {
      width: 100%;
    }

    @include mobile() {
      width: 100%;
    }
  }

  select {
    line-height: 1.5;
    padding-bottom: 3px;
  }

  button {
    border: none;
    cursor: pointer;
    width: 140px;
    -webkit-appearance: none;
    line-height: 1.5;
  }

  .align-right {
    text-align: right;
    width: 100%;

    button {
      display: inline-block;
    }
  }

  ._notice {
    font-size: 0.75rem;
    max-width: 50%;
    margin-top: $spacing-y;

    @include mobile() {
      max-width: 100%;
    }
  }
}
