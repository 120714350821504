// colors
$white: #fff;
$black: #000000;
$color-primary: #25282A;
$color-accent: #FFD100;
$color-bg-default: $white;
$color-bg-light: $color-accent;
$color-bg-dark: $color-primary;

$color-text-muted: #000000;
$color-card-separator: #e3e3e3;


// FONT VARIABLES
$font-primary: roboto, sans-serif;
$font-heading: futura-pt, sans-serif;
$font-weight-bold: 500;


// font sizes
$font-size-xs: 12px;        // 12px
$font-size-sm: 14px;       // 14px
$font-size-default: 16px;     // 16px
$font-size-md: 18px;      // 18px
$font-size-lg: 28px;       // 28px
$font-size-xl: 32px;          // 32px

$font-size-base: $font-size-default;
$font-size-base-tablet: $font-size-sm;
$font-size-base-mobile: $font-size-sm;

// GUTTERS
$default-gutter: .875rem;           // 14px

$content-gutter: $default-gutter;
$spacing-x: $default-gutter;        // 14px
$spacing-y: $default-gutter * 1.5;  // 21px


// media query width
$mobile-sm: 350px;
$mobile: 767px;
$tablet: 998px;
$desktop: 1200px;
$desktop-large: 1921px;

$width-logo: 150px;
$width-container: 1200px;
