// assets\stylesheets\abstracts\_mixins.scss
@mixin debug($color: magenta) {
  outline: 3px solid $color;
}

@mixin paddingX($left, $right: 0) {
  padding-left: $left;
  @if ($right == 0) {
    padding-right: $left;
  } @else {
    padding-right: $right;
  }
}

@mixin paddingY($top, $bottom: 0) {
  padding-top: $top;
  @if ($bottom == 0) {
    padding-bottom: $top;
  } @else {
    padding-bottom: $bottom;
  }
}

@mixin marginX($left, $right: 0) {
  margin-left: $left;
  @if ($right == 0) {
    margin-right: $left;
  } @else {
    margin-right: $right;
  }
}

@mixin marginY($top, $bottom: 0) {
  margin-top: $top;
  @if ($bottom == 0) {
    margin-bottom: $top;
  } @else {
    margin-bottom: $bottom;
  }
}


@mixin centered-horizontal() {
  left: 50%;
  transform: translateX(-50%);
}

@mixin centered-vertical($offset: 0%) {
  top: 50%;
  transform: translateY((-50% + $offset));
}

@mixin centered() {
  top: 50%; left: 50%;
  transform: translate(-50%, -50%);
}


@mixin container() {
  margin: 0 auto;
  @include paddingX(6rem);

  @include desktop() {
    padding-inline: 3rem; 
  }

  @include tablet() {
    @include paddingX($content-gutter * 3);
  }

  @include mobile() {
    @include paddingX($content-gutter * 1.5);
  }
}

@mixin underline($color) {
  border-bottom: 2px solid $color;
  padding-bottom: 1px;
}

//--------------------------------------------------------------------------------------
//  media queries
//--------------------------------------------------------------------------------------

@mixin mobile-sm {
  @media (max-width: $mobile-sm) {
    @content;
  }
}

@mixin mobile {
  @media (max-width: $mobile) {
    @content;
  }
}

@mixin tablet {
  @media (max-width: $tablet) {
    @content;
  }
}

@mixin desktop {
  @media (max-width: $desktop) {
    @content;
  }
}


@mixin desktop-large {
  @media (min-width: $desktop-large) {
    @content;
  }
}
