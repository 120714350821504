body > footer {
  background-color: $color-primary;
  padding: ($spacing-y * 2) 0;

  @include mobile() {
    text-align: center;
  }

  a {
    font-weight: 300;
  }

  li,
  a,
  h3 {
    flex-basis: 100%;
    color: $white;
    margin-bottom: $spacing-y * 0.75;
    line-height: 1.5;
  }
  
  h3 {
    font-size: 2rem;
  }
  
  ._solutions,
  ._socials-desktop,
  ._insite,
  ._contact {
    h3 {
      font-weight: 500;
      font-size: 1.4rem;
    }
  }

  a:not(.logo) {
    border-bottom: 2px solid transparent;
    padding-bottom: 1px;
    &:hover {
      @include underline($color-accent);
    }
  }

  h3 {
    font-weight: $font-weight-bold;
  }

  ._content {
    margin: $spacing-y 0;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;

    & > * {
      flex-basis: 22.5%;

      @include mobile() {
        flex-basis: 100%;
        margin-bottom: $spacing-y;
      }
    }

    nav {
      ul {
        display: flex;
        flex-wrap: wrap;
      }
    }
  }

  ._solutions {
    .js-toggle-icon {
      display: none;
    }

    @include mobile() {
      margin-bottom: 0;

      .js-toggle-icon {
        display: inline;
      }

      h3 {
        cursor: pointer;
        display: inline-block;
      }

      ul {
        display: none !important;

        &.active {
          display: block !important;
        }
      }
    }
  }


  ._insite {
    @include mobile() {
      margin-bottom: 0;
    }
  }

  ._socials-desktop,
  ._contact {
    a {
      display: inline-block;
      margin-bottom: 0;
    }
  }

  ._socials-desktop {
    i {
      margin-right: 0.5rem;
    }

    @include mobile() {
      display: none;
    }
  }

  ._socials-mobile {
    display: none;
    margin-top: $spacing-y * 1.5;

    i {
      font-size: 2rem;
    }

    @include mobile() {
      display: block;
    }

    li {
      display: inline-block;
      margin: 0 0.5rem;
    }
  }

  ._contact {
    h3 a {
      font-weight: $font-weight-bold;
      font-family: $font-heading;
    }

    > a {
      margin-bottom: .5rem;
    }
  }
}

#bolean {
  text-align: center;
  padding: 0.75rem 0;
  color: #bbb;
  font-size: 0.9rem;

  a {
    color: #bbb;
  }

  a:hover {
    color: $color-accent;
  }
}
