body > header {
  background-color: $color-primary;
  padding: $spacing-y 0;
  color: $white;
  position: relative;

  @include tablet() {
    height: 75px;
  }

  a:not(.logo):hover,
  ._solutions-link span:hover {
    @include underline($color-accent);
  }

  & > .container {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include tablet() {
      height: 100%;
    }
  }

  ._about-menu,
  ._solutions {
    cursor: pointer;

    &.active {
      span {
        color: $color-accent;
      }

      @include tablet() {
        a {
          color: $white !important;
        }
      }
    }

    i {
      transition: all .3s ease-in-out;
      color: $white;
    }

    &.active-icon {
      i {
        transform: rotate(-180deg);
      }
    }

    ul {
      display: none;
    }

    @include tablet() {
      cursor: default;

      ul {
        display: block;
      }

      li {
        margin-bottom: $spacing-y * 2;

        &:last-of-type {
          margin-bottom: 0;
        }
      }

      a {
        font-size: 1.25rem;
      }

      span:hover {
        border: none;
      }

      i {
        display: none;
      }
    }
  }

  nav._main {
    li.active a {
      color: $color-accent;
    }

    ul {
      gap: 2.5rem;

      @include tablet(){
        gap: 0;

        a, span {
          font-size: 1.8rem;

        }
        ul a {
          font-size: 1.3rem;
        }
      }
    }
  
  }

  .burger {
    display: none;
  }

  .about-menu,
  .solutions {
    display: none;
    color: $white;
    padding: $spacing-y 0 ($spacing-y * 2);
    position: absolute;
    top: 100%;
    width: 100%;
    background-color: $color-primary;
    z-index: 5;

    &.active {
      display: block;

      @include tablet() {
        display: none;
      }
    }

    h2 {
      line-height: 1.25;
    }

    nav ul {
      display: block;

      li {
        margin-bottom: $spacing-y;

        &:last-of-type {
          margin-bottom: 0;
        }
      }

      a {
        font-size: 1.75rem;
        font-weight: 400;
      }
    }

    & > .container {
      display: flex;
      justify-content: space-between;
    }

    ._content {
      width: 50%;
    }

    ._content {
      padding-right: $spacing-x * 2;
    }

    nav {
      padding-left: $spacing-x * 2;
    }
  }

  @include tablet() {
    .logo {
      width: 33%;
      min-width: 120px;
    }

    padding: ($spacing-y / 4) 0;

    nav._main {
      display: none;

      &.active {
        background-color: $color-primary;
        display: flex;
        align-items: center;
        justify-content: center;
        position: fixed;
        top: 75px;
        left: 0;
        right: 0;
        width: 100%;
        z-index: 10;
        height: calc(100vh - 55px);
      }

      & > ul {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: calc(50% - 45px);
        transform: translateY(-50%) translateY(45px);
        text-align: center;

        li {
          margin: 1rem 0;
          font-size: 1.75rem;
        }
      }
    }

    .burger {
      display: inline-block;
      color: $white;
      font-size: 1.25rem;
      cursor: pointer;
    }
  }

  @include mobile-sm() {
    nav._main {
      ul > li {
        font-size: 1.25rem;
      }

      ._solutions {
        a {
          font-size: 1rem;
        }
      }
    }
  }
}
