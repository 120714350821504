.btn {
  padding: ($spacing-y / 2.5) $spacing-x;
  text-transform: uppercase;
  display: inline-block;
  font-size: 14px;
  background-color: $color-primary;
  color: $white;
  transition: background-color .3s ease-in-out, color .3s ease-in-out;

  @include mobile() {
    font-size: 12px;
  }

  &:hover {
    background-color: $white;
    color: $color-primary;
  }

  &.-pale-bg:hover {
    background-color: $color-accent;
    color: $color-primary;
  }

  &.btn-accent {
    background-color: $color-accent;
    color: $color-primary;

    &:hover {
      background-color: $color-primary;
      color: $color-accent;
    }
  }

  &.btn-play {
    border: none;
    font-family: $font-primary;
    text-transform: none;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 500;
    border-radius: 24px;
    cursor: pointer;

    &:hover svg {
      #Ellipse_1 { stroke: $color-accent; }
      #Polygon_2 { stroke: $color-accent; }
    }

    span { margin-right: 0.5rem; }
  }
}
